<template>
  <v-layout class="mb-4 pb-4" wrap align-start row>
    <v-flex xs12>
      <Title title="General" subtitle=""></Title>
    </v-flex>
    <v-flex xs12>
      <v-card>
        <v-card-text>
          <v-layout class="py-2 px-2 border-bottom" wrap align-start row>
            <v-flex xs6 class="body-2">
              Modo Oscuro
            </v-flex>
            <v-flex xs6>
              <v-radio-group v-model="dark" class="pa-0 ma-0" :mandatory="false">
                <v-radio color="secondary" label="Habilitar modo claro" :value="false"></v-radio>
                <v-radio color="secondary" label="Habilitar modo oscuro" :value="true"></v-radio>
              </v-radio-group>
            </v-flex>
            <v-flex xs6 class="body-2">
              Color Primario
            </v-flex>
            <v-flex xs6>
              <v-text-field
                v-model="colorPrimary"
                label="Definir Color"
                type="color"
                placeholder="#000000"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 class="my-2">
              <v-divider style="opacity:0.3"></v-divider>
            </v-flex>
            <v-flex xs12 sm4 class="body-2">
              Impresora por defecto
            </v-flex>
            <v-flex xs12 sm8 class="pl-3">
              <v-select
                v-model="settings.printer"
                color="secondary"
                :items="printers"
                label="Listado de impresoras"
                hide-details
              ></v-select>
            </v-flex>
            <v-flex xs12 class="my-2">
              <v-divider style="opacity:0.3"></v-divider>
            </v-flex>
            <v-flex xs12 sm4 class="body-2">
              Sonido notificación
            </v-flex>
            <v-flex xs12 sm8 class="pl-3">
              <v-select
                v-model="settings.soundNotification"
                color="secondary"
                :items="sounds"
                item-text="name"
                item-value="id"
                label="Listado de sonidos"
                hide-details
                @change="playSound('soundNotification')"
              ></v-select>
            </v-flex>
            <v-flex xs12 class="my-2">
              <v-divider style="opacity:0.3"></v-divider>
            </v-flex>
            <v-flex xs12 sm4 class="body-2">
              Pagina de inicio
            </v-flex>
            <v-flex xs12 sm8 class="pl-3">
              <v-select
                v-model="settings.page"
                color="secondary"
                :items="listPages"
                item-text="name"
                item-value="id"
                label="Paginas disponibles"
                hide-details
              ></v-select>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="accent" :loading="loading" :disabled="loading" flat @click="update">
            Listo
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import Title from '../useful/title.vue'
import qz from 'qz-tray'

export default {
  name: 'SettingGeneral',
  components: {
    Title
  },
  props: ['config', 'user'],
  data() {
    return {
      loading: false,
      dateContest: null,
      activeContest: false,
      productID: false,
      modal: false,
      printers: [],
      printer: 'POS-80',
      sounds: [
        { id: 'to-the-point.mp3', name: 'to-the-point.mp3' },
        { id: 'stairs.mp3', name: 'stairs.mp3' },
        { id: 'exquisite.mp3', name: 'exquisite.mp3' },
        { id: 'ding.mp3', name: 'ding.mp3' },
        { id: 'definite.mp3', name: 'definite.mp3' },
        { id: 'coins.mp3', name: 'coins.mp3' },
        { id: 'appointed.mp3', name: 'appointed.mp3' }
      ],
      listPages: [
        { id: 'index', name: 'Inicio' },
        { id: 'pedidos', name: 'Pedidos' },
        { id: 'ordersMap', name: 'Mapa' },
        { id: 'cashDesk', name: 'Caja' }
      ]
    }
  },
  computed: {
    dark: {
      get() {
        const dark = this.$store.getters.getDark
        return dark
      },
      set(value) {
        this.$store.dispatch('changeDark', { dark: value })
        this.$vuetify.theme = this.$store.getters.getTheme
      }
    },
    colorPrimary: {
      get() {
        const theme = this.$store.getters.getTheme
        return theme.primary
      },
      set(value) {
        this.$store.dispatch('setColorPrimary', value)
        this.$vuetify.theme = this.$store.getters.getTheme
      }
    },
    settings() {
      return this.$store.getters.getSettings
    }
  },
  mounted() {
    this.get()
    qz.websocket.connect().then(() => {
      qz.printers.find().then(printers => {
        this.printers = printers
      })
    })
  },
  methods: {
    playSound(e) {
      const audio = new Audio(`${process.env.BASE_URL}sound/${this.settings[e]}`)
      audio.play()
    },
    update() {
      this.loading = true
      setTimeout(() => {
        this.$store.dispatch('setSettings', this.settings)
        this.loading = false
      }, 1000)
    },
    get() {
      this.dateContest = this.config.COMPETITION_POLL_DATE
      // eslint-disable-next-line eqeqeq
      this.activeContest = this.config.COMPETITION_POLL == 'true' || this.config.COMPETITION_POLL == '1'
      this.productID = this.config.COMPETITION_POLL_PRODUCT
    }
  }
}
</script>
